import * as React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CenterWrap = styled.div`
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;

  h1 {
    margin-bottom: 2rem;
  }
`

const TermsOfUsePage = () => (
  <Layout>
    <Seo title="Terms Of Use" />
    <CenterWrap>
      <h1>Terms Of Use</h1>
      <p>
        Your access to and use of this site is subject to the following terms
        and conditions and all applicable laws. By accessing and using this
        site, you will be deemed to agree to the following terms and conditions.
      </p>
      <p>
        All the information and material provided on this web site is solely for
        illustrative purposes and not intended as personal medical advice. It is
        not intended to and does not create any business, medical, contractual
        or employment relationship with our clinic. Information and other
        materials posted on our site are not intended to amount to advice on
        which reliance should be placed. We therefore disclaim all liability and
        responsibility arising from any reliance placed by any visitor to the
        site or anyone who may be informed of any of its contents.{" "}
      </p>
      <p>
        All content of this site including videos, graphics, images and text is
        the property of our clinic or partners of our clinic and may not be
        copied, reproduced, republished, posted, transmitted or distributed for
        commercial use in any way without our express, advance, written consent.{" "}
      </p>
      <p>
        All trademarks and logos used or referred to in this site are the
        property of their respective owners.
      </p>
    </CenterWrap>
  </Layout>
)

export default TermsOfUsePage
